.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-content: stretch;
}


.content {
  flex-grow: 3;
}
